import React, { useEffect, useState } from "react";
import "../../assets/scss/_menu.scss";
import { Select, Space } from "antd";
import { IoIosClose } from "react-icons/io";
import { Link, useParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import axios from "axios";
const Menu = (props) => {
  const productSlug = useParams();
  const [menus, setMenus] = useState([]);
  const [categories, setCategories] = useState([]);
  const [restaurant, setRestaurant] = useState();
  const [viewItem, setViewItem] = useState({});
  const [language, setLanguage] = useState("en");
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const [options, setOptions] = useState([
    {
      value: "en",
      label: "EN",
    },
    {
      value: "ar",
      label: "AR",
    },
  ]);
  let data = {
    menu_link: productSlug,
  };
  const handleChange = (value) => {
    setLanguage(value);
  };
  useEffect(() => {
    axios
      .post("https://stmbackend.gap-dynamics.com/api/menus/list", data)
      .then(function (response) {
        let missin_arabic_lang = [];
        for (let cate of response.data.data) {
          for (let _menu of cate.menus) {
            if (
              _menu.arabic_name !== null ||
              _menu.arabic_description !== null
            ) {
              missin_arabic_lang.push({
                id: _menu.id,
                arabic_name: _menu.arabic_name,
                arabic_description: _menu.arabic_description,
              });
            }
          }
        }
        if (missin_arabic_lang.length == 0) {
          let skipAr = options.filter((lang) => lang.value !== "ar");
          setOptions(skipAr);
        }
        setMenus(response.data.data);
        setCategories(response.data.categories.filter((a) => a.name != "All"));
        setRestaurant(response.data.restaurant);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [productSlug]);
  const itemViews = (item) => {
    setViewItem(item);
    axios
      .post("https://stmbackend.gap-dynamics.com/api/menu/view", {
        id: item.id,
      })
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  };

  const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };

  const navbarlinksActive = () => {
    let navbarlinks = select("section", true);
    let position = window.scrollY + 200;
    navbarlinks.forEach((navbarlink) => {
      let section = navbarlink;
      if (!section) return;
      if (
        position >= section.offsetTop &&
        position <= section.offsetTop + section.offsetHeight
      ) {
        if (selectedTab != parseInt(section.getAttribute("index"))) {
          setSelectedTab(parseInt(section.getAttribute("index")));
        }
      }
    });
  };

  const onClickCate = (key) => {
    document.getElementById("cate-" + key).scrollIntoView({
      behavior: "instant",
      block: "start",
    });
  };

  window.addEventListener("scroll", function () {
    navbarlinksActive();
  });

  if (menus.length < 1) {
    return (
      <div className="spinner-main">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="menu">
      <Space wrap className="custom-lang">
        <Select
          defaultValue="en"
          style={{
            width: 70,
          }}
          onChange={handleChange}
          options={options}
        />
      </Space>
      <div className="menu-header">
        <img
          className="img-fluid menu-img"
          src={restaurant?.cover_image}
          alt="recipe"
        />
        <div className="menu-nav"></div>
      </div>
      <div className={`menu-details ${language}`}>
        <div className="menu-logo">
          <div className="menu-img">
            <img className="img-fluid" src={restaurant?.logo} alt="recipe" />
          </div>
          <div>
            <h3 className="restraunt-name">
              {language === "ar" ? restaurant.arabic_name : restaurant?.name}
            </h3>
            <p>
              <span>AED {restaurant?.min_order} Minimum </span>
            </p>
          </div>
        </div>
      </div>
      <div className={`menu-list ${language}`}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {categories.map((item, key) => (
            <Tab
              className="custom-tab"
              onClick={(e) => onClickCate(key)}
              id={`b-${key}`}
              label={
                language === "ar"
                  ? item.arabic_name
                    ? item.arabic_name
                    : item.name
                  : item.name
              }
              key={key}
            />
          ))}
        </Tabs>
      </div>
      <div id="combo" className="fav">
        {menus &&
          menus.map((menu, key) => (
            <div id={`cate-${key}`} key={key}>
              <h4>&nbsp;</h4>
              <section
                className={`category-divider ${language}`}
                value={selectedTab}
                index={key}
              >
                <h3
                  onClick={(e) => setSelectedTab(key)}
                  className={`${language}`}
                >
                  {menu && language === "ar"
                    ? menu.arabic_name
                      ? menu.arabic_name
                      : menu.name
                    : menu.name}
                </h3>
                {menu.menus.map((item, index) => (
                  <div
                    key={index}
                    className="fav-box"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={(e) => itemViews(item)}
                  >
                    <div>
                      <h4>
                        {language === "ar"
                          ? item.arabic_name
                            ? item.arabic_name
                            : item.name
                          : item.name}
                      </h4>
                      <p>
                        {language === "ar"
                          ? item.arabic_description
                            ? item.arabic_description
                            : item.description
                          : item.description}
                      </p>
                      <p className="mb-0 item-price">AED &nbsp;{item.price}</p>
                    </div>
                    <div className="img-box">
                      <img
                        className="img-fluid"
                        src={item.image}
                        alt="recipe"
                      />
                    </div>
                  </div>
                ))}
              </section>
            </div>
          ))}
        {/* < Modal */}
        <div
          className="modal fade item-modal"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <IoIosClose />
              </button>
              <div className="modal-body">
                <div className="modal-img">
                  <img
                    className="img-fluid"
                    src={viewItem?.image}
                    alt="recipe"
                  />
                </div>
                <div className={`modal-cont ${language}`}>
                  <div className="item-cont">
                    <h3>
                      {language === "ar"
                        ? viewItem.arabic_name
                          ? viewItem.arabic_name
                          : viewItem.name
                        : viewItem.name}
                    </h3>
                    <p>AED {viewItem?.price}</p>
                  </div>
                  <p className="item-desc">
                    {language === "ar"
                      ? viewItem.arabic_description
                        ? viewItem.arabic_description
                        : viewItem.description
                      : viewItem.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Menu;
