import React, { useState, useEffect } from "react";
import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Components
import Header from "./components/Header/Header";
// Routes
import QrCode from "./pages/QrCode/QrCode";
import Menu from "./pages/Menu/Menu";

function App() {
  const [language, setLanguage] = useState("gm");
  const expiryTime = localStorage.getItem("expiryTime");

  useEffect(() => {
    if (expiryTime) {
      let now = new Date();
      let expiry = new Date(expiryTime);
      console.log("Now", now.toString());
      console.log("Expiry", expiry.toString());
      if (now > expiry) {
        localStorage.clear();
        window.location.reload();
      }
    }
  });

  return (
    <BrowserRouter>
      {/* <Header pageName="All" /> */}
      <Routes>
        <Route exact path="/:productSlug" element={<Menu />} />
        <Route exact path="/qr-code" element={<QrCode />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
