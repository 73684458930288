import React from "react";
import "../../assets/scss/_qr-code.scss";
import { Input, QRCode, Space } from "antd";

const QrCode = () => {
  const [text, setText] = React.useState(
    "https://www.npmjs.com/package/react-icons"
  );
  return (
    <div className="qr-screen">
      <div>
        <div className="qr-content">
          <h5>Scan QR Code</h5>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.{" "}
          </p>
        </div>

        <QRCode className="custom-qr" value={text || "-"} />
        {/* <Input
            placeholder="-"
            maxLength={60}
            value={text}
            onChange={(e) => setText(e.target.value)}
          /> */}
      </div>
    </div>
  );
};

export default QrCode;
